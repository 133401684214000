<template>
  <div>
    <el-dialog title="设置活动规则" :visible.sync="rulesShow" width="50%" @close="closeEvent">
      <template>
        <div style="display: flex;align-items: center;justify-content: flex-start;margin-bottom: 20px;">
          <p>活动类型：</p>
          <template>
            <el-radio-group v-model="actType" @change="activityTypeChange">
              <el-radio :label=" 0 ">满减</el-radio>
              <el-radio :label=" 1 ">满赠</el-radio>
            </el-radio-group>
          </template>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-start;margin-bottom: 20px;">
          <p>选择规格：</p>
          <el-select filterable v-model="ids" multiple clearable placeholder="请选择规格（可多选）" style="width: 250px" @change=" changeGuiGe ">
            <el-option v-for="( item, index ) in  guiGeList " :key=" index " :label=" item.standard " :value=" item.id ">
            </el-option>
          </el-select>
        </div>
        <el-table :data="ruleLists" style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="threshold" label="购买数量">
            <template slot-scope="scope">
              <el-input v-model=" scope.row.threshold " placeholder="请输入购买数量" style="width: 150px"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="discounts" label="单价(元)" v-if=' actType == 0 '>
            <template slot-scope="scope">
              <el-input v-model=" scope.row.discounts " placeholder="请输入对应单价" style="width: 150px"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="giftId" label="选择赠品" v-if=' actType == 1 '>
            <template slot-scope="scope">
              <el-select filterable v-model=" scope.row.giftId " clearable placeholder="请选择赠品" style="width: 180px" @change="giftChange($event,scope.$index)">
                <el-option v-for="( item, index ) in  giftList " :key=" index " :label=" item.giftName " :value=" item.id ">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="discounts" label="赠品数量" v-if=' actType == 1 '>
            <template slot-scope="scope">
              <el-input v-model=" scope.row.discounts " placeholder="请输入赠品数量" style="width: 150px"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="addRules">添 加</el-button>
              <el-button size="mini" type="danger" @click=" delRules(scope.$index) ">删 除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitRules">确 定</el-button>
        <el-button @click="closeEvent">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 活动类型 0满减 1满赠
      actType: 0,
      giftList: [],
      // 设置活动时选中的销售产品信息
      nowGoodsData: {},
      ruleLists: [],
      ids: [],
      rulesShow: false,
    }
  },

  props: {
    ruleList: {
      type: Array
    },
    guiGeList: {
      type: Array
    },
    giftGoodsList: {
      type: Array
    },
    activityType: {
      type: Number
    },
    goodsIds: {
      type: Array
    },
    dialogShow: {
      type: Boolean
    },
    salesGoodsId:{
      type:String
    },
    dialogTitle:{
      type:String
    }
  },
  watch: {
    goodsIds: {
      handler(n) {
        console.log(n)
        this.ids = n
      },
      immediate: true
    },
    activityType: {
      handler(n) {
        console.log(n)
        this.actType = n
      },
      immediate: true
    },
    ruleList: {
      handler(n) {
        console.log(n)
        this.ruleLists = n
      },
      immediate: true
    },
    dialogShow: {
      handler(n) {
        console.log(n)
        this.rulesShow = n
      },
      immediate: true
    },
    giftGoodsList: {
      handler(n) {
        console.log(n)
        this.giftList = n
      },
      immediate: true
    },
  },
  methods: {
    // 选择赠品时获取赠品名称 加入数据用于回显
    giftChange(e,index){
      console.log(e,index)
      let giftName = ''
      this.giftList.forEach(item=>{
        if(item.id == e){
          giftName = item.giftName
        }
      })
      this.$set(this.ruleLists[index],'giftName', giftName)
    },
    changeGuiGe(e) {
      console.log(e)
    },
    // 选择活动类型
    activityTypeChange(e) {
      if (e == 1) {
        this.getGiftList()
      }

      if (this.$props.activityType != e) {
        this.ruleLists = [{}]
      } else {
        this.ruleLists = this.$props.ruleList
      }
    },
    // 获取赠品列表
    getGiftList() {
      let _this = this;
      // 获取赠品列表
      this.$axios
        .get(this.$axios.defaults.basePath + '/goodsMall/getList?isGift=1')
        .then((res) => {
          console.log(res)
          if (res.data.errcode == 0) {
            if (res.data.data.length == 0) {
              _this.$message({
                message: '暂无赠品数据',
                type: 'warning'
              })
            } else {
              res.data.data.forEach(item => {
                item.giftName = item.name + '（' + item.standard + '）'
              })
              _this.giftList = res.data.data
            }
          }
        })
    },
    addRules(index) {
      let json = {
        discounts: '',
        threshold: '',
      }
      if(this.actType == 1){
        json.giftId = ''
        json.type = this.actType
      }
      this.ruleLists.push(json)
    },
    delRules(index) {
      this.ruleLists.splice(index, 1)
    },
    // 提交规则
    submitRules() {
      let _this = this;
      if (this.ruleLists.length == 0) {
        this.$message({
          message: '请至少设置一条规则',
          type: 'warning'
        })
      } else {
        let flag = true
        if (this.actType == 0) {
          for (let i = 0; i < this.ruleLists.length; i++) {
            if (this.ruleLists[i].discounts == '' || this.ruleLists[i].threshold == '') {
              this.$message({
                message: '购买数量和单价不能为空',
                type: 'warning'
              })
              flag = false
              break;
            } else {
              flag = true
            }
          }
        } else {
          if(this.$props.guiGeList.length > 0 && this.ids.length == 0) {
            this.$message({
              message: '请选择规格',
              type: 'warning'
            })
            return
          }
          for (let i = 0; i < this.ruleLists.length; i++) {
            if (this.ruleLists[i].giftId == '' || this.ruleLists[i].discounts == '' || this.ruleLists[i].threshold == '') {
              this.$message({
                message: '购买数量、赠品和赠品数量不能为空',
                type: 'warning'
              })
              flag = false
              break;
            } else {
              flag = true
            }
          }
        }

        let subData = {
          goodsIds: _this.ids.join(','),
          goodsMallActivityRuleList: _this.ruleLists,
          goodsTemplateId: _this.salesGoodsId,
          type: _this.actType
        }
        if (flag) {
          if(this.dialogTitle == '设置活动规则'){
            this.$axios({
              url: _this.$axios.defaults.basePath + '/goodsMallActivityRule/updateMultiSpecificationActivities',
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify(subData),
            }).then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: '操作成功',
                  type: "success",
                });
                 _this.$emit('pushRules', subData, _this.actType)
                _this.rulesShow = false;
                // _this.actType = 0;
                // _this.ruleLists = []
                
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
          }else{
            _this.ruleLists.forEach(item=>{
              if(!item.type){
                item.type = _this.actType
              }
            })
            _this.$emit('pushRules', _this.ruleLists, _this.actType)
            _this.rulesShow = false;
          }
          
        }

      }

    },
    closeEvent() {
      this.rulesShow = false;
      this.actType = 0
      this.$emit('closeClick')
    },
  }
}
</script>

<style scoped></style>